import { noticeError } from './newRelicUtils';

async function handleResponse(requestUrl: string, response: Response) {
    if (!response.ok) {
        throw Error(
            `Request: ${requestUrl} | ${response.status} ${response.statusText} | Response: ${JSON.stringify(response)}`
        );
    }
    try {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.indexOf('application/json') > -1) {
            const json = await response.json();
            return json;
        }
        const text = await response.text();
        return text;
    } catch (error) {
        throw new Error(
            `Request: ${requestUrl} | ${response.status} ${response.statusText}
            | Error: ${JSON.stringify(error)}`
        );
    }
}

interface CommonRequestOptions {
    headers?: Record<string, string>;
    query?: Record<string, string> | string;
    signal?: AbortSignal;
    onError?: () => void;
    method: string;
    requestInitiator: string; // calling function
    data?: Record<string, string>; // any optional additional data
}

interface NoPayloadRequestOptions extends CommonRequestOptions {
    method: 'GET' | 'DELETE' | 'HEAD';
}

interface PayloadRequestOptions extends CommonRequestOptions {
    method: 'POST' | 'PATCH' | 'PUT';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: any;
}

type RequestOptions = NoPayloadRequestOptions | PayloadRequestOptions;

export function handleError(
    error: unknown,
    requestInitiator: string,
    onError?: () => void,
    requestUrl?: string,
    data?: Record<string, string>
) {
    // ignore error related to aborted request
    if (!(error instanceof Error && error.message.includes('abort'))) {
        // send error log to new relic
        noticeError(error, {
            method: requestInitiator,
            requestUrl,
            ...data,
        });
        if (onError) {
            onError();
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchWrapper<T = any>(path: string, options: RequestOptions): Promise<T | undefined> {
    const { method, headers, query, signal, requestInitiator, data, onError } = options;
    const reqOptions: RequestInit = { method, headers };

    if (signal) {
        reqOptions.signal = signal;
    }

    if (method === 'POST' || method === 'PATCH' || method === 'PUT') {
        const { body } = options as PayloadRequestOptions;
        if (typeof body === 'object') {
            reqOptions.body = JSON.stringify(body);
            reqOptions.headers = {
                ...reqOptions.headers,
                'Content-Type': 'application/json',
            };
        }
    }

    const queryParams = query ? new URLSearchParams(query).toString() : '';
    const requestUrl = `${path}${queryParams ? `?${queryParams}` : ''}`;

    try {
        const response = await fetch(requestUrl, reqOptions);
        const responseData = await handleResponse(requestUrl, response);
        return responseData;
    } catch (error) {
        handleError(error, requestInitiator, onError, requestUrl, data);
    }
}
