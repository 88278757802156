import React from 'react';
import { PanelState } from '@design-stack-vista/cimdoc-state-manager';
import { useDesignEngine } from '@design-stack-vista/core-features';
import { css } from '@emotion/css';
import {
    className,
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogNav,
    tokens,
} from '@vp/swan';
import { observer } from 'mobx-react-lite';
import { DesignPreview } from './DesignPreview';
import { DesignPreviewHeader } from './DesignPreviewHeader';
import { useProductConfiguration } from '../../hooks/calcifer/useProductConfiguration';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { usePreview } from '../../providers/PreviewProvider';
import { text } from '../../utils/localization';
import { useDesignPreview } from '../quad/recipes/useDesignPreview';
import { ModalDialogVariant } from '../quad/types';

const getModalStyles = (isSmall: boolean) => css`
    .swan-modal-dialog.swan-modal-dialog-panel.swan-modal-dialog-panel-bottom {
        border-radius: ${tokens.SwanSemBorderRadiusContainer} ${tokens.SwanSemBorderRadiusContainer} 0 0;
    }

    .swan-modal-dialog-nav {
        position: absolute;
        background: transparent;
        right: 0;
        top: 0;
    }

    ${!isSmall &&
    `.stylized-dialog-visible {
            max-width: 100vw;
    }`}
`;

const getTopOffset = (isSmall: boolean, is3DViewSupported: boolean) =>
    isSmall ? (is3DViewSupported ? '110px' : '40px') : '60px';

const getBottomOffset = (isSmall: boolean, panels: PanelState[]) =>
    panels.length > 1 ? (isSmall ? '66px' : '104px') : '104px';

const getPreviewStyle = (isSmall: boolean, is3DViewSupported: boolean, panels: PanelState[]) => {
    const topOffset = getTopOffset(isSmall, is3DViewSupported);
    const bottomOffset = getBottomOffset(isSmall, panels);

    return css`
        padding-bottom: ${isSmall ? tokens.SwanSemSpaceNone : tokens.SwanSemSpace6};
        padding-top: ${tokens.SwanSemSpace6};
        width: ${isSmall ? 'auto' : '75vw'};
        height: calc(75vh - ${topOffset} - ${bottomOffset});
        .two-d-preview {
            width: auto;
            height: calc(75vh - ${topOffset} - ${bottomOffset});
            > div {
                width: ${isSmall ? 'auto' : '75vw'} !important;
                height: 100% !important;
            }
        }
    `;
};

/**
 * A stylized Swan ModalDialog component, content, body, and close button.
 * It must be wrapped inside the `LocalizedProvider` component.
 *
 * @param props {DesignPreviewModalProps}
 * @returns JSX.Element
 */
const DesignPreviewModal = observer((): JSX.Element | null => {
    const {
        cimDocStore: { panels },
    } = useDesignEngine();
    const { is3DViewSupported } = usePreview();
    const { productData } = useProductConfiguration();
    const { isSmall } = useScreenLayout();
    const { showPreviewModal, onClose } = useDesignPreview();

    if (!productData) {
        return null;
    }

    return (
        <ModalDialog
            variant={isSmall ? ModalDialogVariant.PANEL_BOTTOM : ModalDialogVariant.STANDARD}
            isOpen={showPreviewModal}
            onRequestDismiss={onClose}
            className={className('stylized-dialog', getModalStyles(isSmall))}
            data-testid="design-preview-modal"
            onlyRenderWhenOpen={isSmall}
        >
            <ModalDialogContent aria-label={text('designPreview')} fullBleed={true} className="stylized-dialog-visible">
                <ModalDialogNav>
                    <ModalDialogCloseButton visuallyHiddenLabel={text('closeAccessibilityLabel')} />
                </ModalDialogNav>
                <ModalDialogBody p={{ xs: '5', sm: '6' }}>
                    <DesignPreviewHeader />
                    <DesignPreview style={getPreviewStyle(isSmall, is3DViewSupported, panels)} />
                </ModalDialogBody>
            </ModalDialogContent>
        </ModalDialog>
    );
});

export default DesignPreviewModal;
