import React from 'react';
import { Button, Icon } from '@vp/swan';
import { DesktopExperience, MobileExperience } from '../../components/Experiences';
import { text } from '../../utils/localization';
import { useDesignPreview } from '../quad/recipes/useDesignPreview';
import { QuickToolItem } from '../QuickTools/QuickToolItem';

export const DesignPreviewModalButton = (): JSX.Element => {
    const { handleButtonClick } = useDesignPreview();

    return (
        <>
            <DesktopExperience>
                <Button
                    iconPosition="left"
                    onClick={handleButtonClick}
                    skin="secondary"
                    width="full-width"
                    data-testid="design-preview-btn"
                >
                    <Icon iconType="passwordShow" />
                    {text('previewButton')}
                </Button>
            </DesktopExperience>
            <MobileExperience>
                <QuickToolItem
                    Icon={<Icon iconType="passwordShow" size="20p" />}
                    buttonLabel={text('previewButton')}
                    onClick={handleButtonClick}
                    dataTestId="design-preview-btn"
                />
            </MobileExperience>
        </>
    );
};
