import { useCallback } from 'react';
import { useCustomEventPropagation } from '@design-stack-vista/custom-event-propagation';
import { useProductConfiguration } from '../../../hooks/calcifer';
import { useActivePanel } from '../../../hooks/useActivePanel';
import { usePreview } from '../../../providers/PreviewProvider';
import { ACTION_NAME } from '../../TrackingEvents/constant';
import { GA_EVENTS } from '../../TrackingEvents/GAEvents';

export const useDesignPreview = () => {
    const { fireEvent } = useCustomEventPropagation();
    const { is3DView } = usePreview();
    const activePanel = useActivePanel();
    const { productData } = useProductConfiguration();
    const { showPreviewModal, setShowPreviewModal, setActivePreview } = usePreview();

    const previewType = is3DView ? '3D' : '2D';
    const reviewSceneConfigs = productData?.scenesConfiguration.review;

    const onClose = useCallback(() => {
        setShowPreviewModal(false);
    }, [setShowPreviewModal]);

    const handleButtonClick = useCallback(() => {
        if (reviewSceneConfigs && reviewSceneConfigs.length > 0) {
            setActivePreview(reviewSceneConfigs[0].title ?? '');
        }

        setShowPreviewModal(true);
        fireEvent({
            name: ACTION_NAME.DESIGN_TOOL_USED,
            extraData: {
                eventDetail: `${GA_EVENTS.CLICK_RIGHT_PANEL_PREVIEW}:${previewType}:${activePanel?.panelProperties.name}`,
            },
        });
    }, [previewType, activePanel?.panelProperties.name, setShowPreviewModal, setActivePreview, reviewSceneConfigs]);

    return { showPreviewModal, handleButtonClick, onClose };
};
